import React from "react"
import { graphql } from "gatsby"
import HalfHeader from "../components/halfBGImageMD"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import { useIntl } from "react-intl"
import ReactPlayer from "react-player"

const BlogPostTemplate = ({ data }, props) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  let imageCounter = 0
  const intl = useIntl()

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <HalfHeader
        data={post.frontmatter.background}
        title={post.frontmatter.title}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="news">{intl.formatMessage({ id: "news" })}</h2>
          <hr />
          <div className="galleryContainer">
            {post.frontmatter.news.map(
              ({ link, id, imageUrl, title, description }) => {
                imageCounter++
                return (
                  <motion.div
                    className="NewsParentFigure"
                    whileHover={{ scale: 1.03 }}
                    transition={{ ease: "easeOut", duration: 0.4 }}
                    key={id}
                    count={imageCounter}
                  >
                    <a
                      href={link}
                      title={title}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        fluid={imageUrl.childImageSharp.fluid}
                        alt={title}
                        className="NewsItselfLarge"
                      />
                      <h3 className="newsTitle">{title}</h3>
                      <p className="newsParagraph">{description}</p>
                    </a>
                  </motion.div>
                )
              }
            )}
          </div>
          <h2 id="news">{intl.formatMessage({ id: "tvNews" })}</h2>
          <hr />
          <div className="galleryContainer">
            {post.frontmatter.videos.map(({ url }) => {
              return (
                <ReactPlayer
                  className="imageVideoFigure"
                  url={url}
                  light={true}
                  config={{
                    youtube: {
                      preload: true,
                      playerVars: {
                        modestbranding: 1,
                        responsive: 1,
                        fs: 0,
                      },
                    },
                  }}
                />
              )
            })}
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query NewsBySlug($locale: String!, $slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      id
      frontmatter {
        title
        description
        background {
          mobileImage: childImageSharp {
            fluid(maxHeight: 490) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          desktopImage: childImageSharp {
            fluid(maxHeight: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        news {
          title
          description
          link
          id
          imageUrl {
            childImageSharp {
              fluid(maxHeight: 490) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        videos {
          url
        }
      }
    }
  }
`
